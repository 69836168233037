import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import facebooklogo from '../../assets/images/facebooklogo.jpg';
import { FaFacebookF } from 'react-icons/fa';

function Footer(props) {
  return (
    <footer>
      <div className='footer-contacts'>
        <div className='footer-card'>
          <img src={logo} alt='Site logo' width='130' />
          <p>is a professional music agency that provides musicians to the hotel and cruise industry. we offer support and advice to promising musicians looking to develop their career overseas, enjoy wonderful opportunities with a variety of top travel companies, and experience performing on an international platform.</p>
        </div>
        <div className='footer-card'>
          <h2>Facebook Feed</h2>
          <div className='facebook-container'>
            <div className='facebook-style'>
              <img src={facebooklogo} alt='Facebook logo' />
              <div className='facebook-title'>
                <h5>SZabo Music Company</h5>
                <Link to='/facebook'>
                  <FaFacebookF />
                  <p>Like Page</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-card'>
          <h2>Get in touch with us</h2>
          <h3>Budapest, Hungary</h3>
          <h4>T: +447445449483, +36309552088</h4>
          <h4>E: miklos@szabomusiccompany.com</h4>
        </div>
      </div>
      <div className='footer-copyright'>
        <p>© Copyright 2021. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
