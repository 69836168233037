import React from 'react';
import { Link } from 'react-router-dom';
import artistsheader from '../../assets/images/artistsheader.jpg';
import ArtistsData from '../data/ArtistsData';

function Artists(props) {
  return (
    <section className='section-header'>
      <header style={{ backgroundImage: `url(${artistsheader})` }}>
        <h2>Lounge Musicians</h2>
      </header>
      {ArtistsData && (
        <div className='artists-category'>
          {ArtistsData.map((artist, i) => (
            <Link to={{ pathname: '/category', state: { data: artist } }}>
              <div className='tile-container'>
                <img src={artist.picture} alt={artist.name} />
              </div>
              <p>{artist.name}</p>
            </Link>
          ))}
        </div>
      )}
    </section>
  );
}

export default Artists;
