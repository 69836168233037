import React from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { IoChevronBackCircleSharp } from 'react-icons/io5';

function Category(props) {
  const location = useLocation();
  const history = useHistory();
  const data = location.state?.data;

  return (
    <section className='section-header'>
      <header style={{ backgroundImage: `url(${data.header})` }}>
        <h2>{data.title}</h2>
      </header>
      <IoChevronBackCircleSharp onClick={() => history.goBack()} className='back-button' />
      <div className='artists-subcategory'>
        {data.bands.map((artist, i) => (
          <Link to={{ pathname: '/artistprofile', state: { data: artist } }}>
            <div className='tile-container'>
              <img src={artist.picture} alt={artist.name} />
            </div>
            <p>{artist.name}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default Category;
