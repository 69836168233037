import React from 'react';
import { FaFileDownload } from 'react-icons/fa';

function Documents(props) {
  return (
    <div className='documents-container'>
      <h3>Documents</h3>
      <div className='documents'>
        <div>
          <h1>Cunard</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
        <div>
          <h1>P&O</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
        <div>
          <h1>RCCL</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
        <div>
          <h1>NCL</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
        <div>
          <h1>Saga Cruises</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
        <div>
          <h1>Fred Olsen</h1>
          <a href='https://drive.google.com/file/d/1DjW0FOBk9emhCVTvazsmXJLUOQFJeY_G/view?usp=sharing'>
            Documents <FaFileDownload />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Documents;
