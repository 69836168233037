import React from 'react';
import emailjs from 'emailjs-com';
import contactimage from '../../assets/images/contactimage.jpg';

function Contact(props) {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_zzr540l', 'template_su0i4di', e.target, 'user_Ao4zvVxATYxQo9oYx3FS3').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  }

  return (
    <section className='contact-section'>
      <div className='contact-container'>
        <div style={{ backgroundImage: `url(${contactimage})` }}>
          <h2>Contact Us</h2>
          <p>Budapest, Hungary</p>
        </div>
        <div>
          <form className='contact-form' onSubmit={sendEmail}>
            <input type='text' name='name' placeholder='Name' />
            <input type='email' name='email' placeholder='Email' />
            <textarea name='message' placeholder='Your message' />
            <input type='submit' value='Send' />
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
