import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { IoChevronBackCircleSharp } from 'react-icons/io5';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';

function Artistprofile(props) {
  const location = useLocation();
  const history = useHistory();
  const data = location.state?.data;

  return (
    <section className='artist-profile'>
      <header style={{ backgroundImage: `url(${data.profile.header})` }} />
      <div className='artist-profile-details'>
        <div className='artist-picture'>
          <img src={data.profile.picture} alt='' />
        </div>
        <div className='artist-musicIcon'>
          <BsMusicNoteBeamed />
        </div>
        <h2>{data.name}</h2>
      </div>
      <IoChevronBackCircleSharp onClick={() => history.goBack()} className='back-button-artist' />
      <div className='artist-profile-description'>
        <div className='artist-profile-text'>
          <p>{data.profile.description}</p>
          {data.profile.available && <p>{data.profile.available}</p>}
          <NavLink to='/contact' activeClassName='active'>
            Contact us for more information
          </NavLink>
        </div>
        <Carousel className='artist-profile-slider' variant='dark'>
          <Carousel.Item>
            <img className='d-block' src={data.profile.slider1} alt='First slide' />
          </Carousel.Item>
          {data.profile.slider2 && (
            <Carousel.Item>
              <img className='d-block' src={data.profile.slider2} alt='First slide' />
            </Carousel.Item>
          )}
          {data.profile.slider3 && (
            <Carousel.Item>
              <img className='d-block' src={data.profile.slider3} alt='First slide' />
            </Carousel.Item>
          )}
        </Carousel>
      </div>
      <div className='artist-profile-video'>
        <ReactPlayer url={data.profile.youtubeone} width='100%' height='100%' />
        {data.profile.youtubetwo && <ReactPlayer url={data.profile.youtubetwo} width='100%' height='100%' />}
      </div>
    </section>
  );
}

export default Artistprofile;
