import { useState, useContext } from 'react';
import { UserContext } from '../../App';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { FaPhoneAlt } from 'react-icons/fa';
import { BiLogIn } from 'react-icons/bi';
import { BiLogOut } from 'react-icons/bi';
import { GrMail } from 'react-icons/gr';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaYoutubeSquare } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';

function Navbar({ setUser }) {
  const user = useContext(UserContext);
  const [active, setActive] = useState(null);

  const logout = () => {
    setUser(null);
    localStorage.removeItem('jwt');
  };

  return (
    <nav>
      <div className='nav-contact'>
        <div>
          <FaPhoneAlt />
          <p>+36309552088</p>
        </div>
        <div>
          <GrMail />
          <p>miklos@szabomusiccompany.com</p>
        </div>
        <div className='social-icons'>
          <Link to='/facebook'>
            <FaFacebookSquare />
          </Link>
          <Link to='/youtube'>
            <FaYoutubeSquare />
          </Link>
          <Link to='/instagram'>
            <FaInstagramSquare />
          </Link>
        </div>
      </div>
      <div className='nav-menu'>
        <img src={logo} alt='Szabo Music Company Logo' />
        <div className='nav-links'>
          {user?.isAdmin && (
            <NavLink to='/admin' activeClassName='active'>
              Admin
            </NavLink>
          )}
          <NavLink to='/' exact activeClassName='active'>
            Home
          </NavLink>
          <NavLink to='/about' activeClassName='active'>
            About us
          </NavLink>
          <NavLink to='/artists' activeClassName='active'>
            Artists
          </NavLink>
          <NavLink to='/contact' activeClassName='active' className='artist-contact'>
            Contact
          </NavLink>
          {user ? (
            <>
              <NavLink to='/documents' activeClassName='active'>
                Documents
              </NavLink>
              <NavLink to='/' activeClassName='active' className='login-icon'>
                <BiLogOut onClick={logout} />
              </NavLink>
            </>
          ) : (
            <NavLink to='/googleauth' activeClassName='active' className='login-icon'>
              <BiLogIn />
            </NavLink>
          )}
        </div>
        {active ? <AiOutlineClose className='mobile-menu' onClick={() => setActive(!active)} /> : <GiHamburgerMenu className='mobile-menu' onClick={() => setActive(!active)} />}
        {active && (
          <div className='mobile-navlinks'>
            <NavLink to='/' exact activeClassName='active' onClick={() => setActive(false)}>
              Home
            </NavLink>
            <NavLink to='/about' activeClassName='active' onClick={() => setActive(false)}>
              About us
            </NavLink>
            <NavLink to='/artists' activeClassName='active' onClick={() => setActive(false)}>
              Artists
            </NavLink>
            <NavLink to='/contact' activeClassName='active' onClick={() => setActive(false)}>
              Contact
            </NavLink>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
