import { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import ScrollToTop from './utils/ScrollToTop';
import Home from './components/main/Home';
import Navbar from './components/main/Navbar';
import Footer from './components/main/Footer';
import Artists from './components/artists/Artists';
import Category from './components/artists/Category';
import Artistprofile from './components/artists/Artistprofile';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Login from './components/auth/Login';
import Documents from './components/admin/Documents';
import Admin from './components/admin/Admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/App.sass';

export const UserContext = createContext(null);

function App() {
  const [user, setUser] = useState(null);

  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&prompt=select_account&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20profile%20email&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/login`;

  const assignToken = async () => {
    let jwt = localStorage.getItem('jwt');
    if (jwt) {
      let token = await jwt_decode(jwt);
      if (token.exp < Date.now() / 1000) {
        setUser(null);
        localStorage.removeItem('jwt');
      } else {
        setUser(token);
      }
    }
  };

  useEffect(() => {
    assignToken();
  }, []);

  return (
    <Router>
      <UserContext.Provider value={user}>
        <ScrollToTop />
        <Navbar setUser={setUser} />
        <Switch>
          {user && !user.isAdmin ? (
            <>
              {/* <Route exact path='/admin' component={Admin} /> */}
              <Route exact path='/' component={Home} />
              <Route exact path='/artists' component={Artists} />
              <Route exact path='/category' component={Category} />
              <Route exact path='/artistprofile' component={Artistprofile} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/documents' component={Documents} />
              <Route
                path='/facebook'
                component={() => {
                  window.location.href = 'https://www.facebook.com/Szabomusiccompany/';
                  return null;
                }}></Route>
              <Route
                path='/youtube'
                component={() => {
                  window.location.href = 'https://www.youtube.com/channel/UCS0kyyyDTTSfWsn56UvxAfQ';
                  return null;
                }}></Route>
              <Route
                path='/instagram'
                component={() => {
                  window.location.href = 'https://www.instagram.com/szabomusiccompany/';
                  return null;
                }}></Route>
            </>
          ) : user && user.isAdmin ? (
            <>
              <Route exact path='/' component={Home} />
              <Route exact path='/artists' component={Artists} />
              <Route exact path='/category' component={Category} />
              <Route exact path='/artistprofile' component={Artistprofile} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/documents' component={Documents} />
              <Route exact path='/admin' component={Admin} />
              <Route
                path='/facebook'
                component={() => {
                  window.location.href = 'https://www.facebook.com/Szabomusiccompany/';
                  return null;
                }}></Route>
              <Route
                path='/youtube'
                component={() => {
                  window.location.href = 'https://www.youtube.com/channel/UCS0kyyyDTTSfWsn56UvxAfQ';
                  return null;
                }}></Route>
              <Route
                path='/instagram'
                component={() => {
                  window.location.href = 'https://www.instagram.com/szabomusiccompany/';
                  return null;
                }}></Route>
            </>
          ) : (
            <>
              <Route exact path='/' component={Home} />
              <Route exact path='/artists' component={Artists} />
              <Route exact path='/category' component={Category} />
              <Route exact path='/artistprofile' component={Artistprofile} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/login' component={Login} />
              <Route
                path='/googleauth'
                component={() => {
                  window.location.href = googleAuthUrl;
                  return null;
                }}></Route>
              <Route
                path='/facebook'
                component={() => {
                  window.location.href = 'https://www.facebook.com/Szabomusiccompany/';
                  return null;
                }}></Route>
              <Route
                path='/youtube'
                component={() => {
                  window.location.href = 'https://www.youtube.com/channel/UCS0kyyyDTTSfWsn56UvxAfQ';
                  return null;
                }}></Route>
              <Route
                path='/instagram'
                component={() => {
                  window.location.href = 'https://www.instagram.com/szabomusiccompany/';
                  return null;
                }}></Route>
            </>
          )}
        </Switch>
        <Footer />
      </UserContext.Provider>
    </Router>
  );
}

export default App;
