import { HashLink } from 'react-router-hash-link';
import { RiUserLine } from 'react-icons/ri';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { GiCoffeeCup } from 'react-icons/gi';
import { BsChatDots } from 'react-icons/bs';
import { MdMessage } from 'react-icons/md';
import { ImMobile } from 'react-icons/im';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { ImQuotesRight } from 'react-icons/im';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
import client1 from '../../assets/images/client1.jpg';
import client2 from '../../assets/images/client2.jpg';
import client3 from '../../assets/images/client3.jpg';
import partner1 from '../../assets/images/partners/01.jpg';
import partner2 from '../../assets/images/partners/02.jpg';
import partner3 from '../../assets/images/partners/03.jpg';
import partner4 from '../../assets/images/partners/04.jpg';
import partner5 from '../../assets/images/partners/05.jpg';
import partner6 from '../../assets/images/partners/06.jpg';
import partner7 from '../../assets/images/partners/07.jpg';
import partner8 from '../../assets/images/partners/08.jpg';
import partner9 from '../../assets/images/partners/09.jpg';
import partner10 from '../../assets/images/partners/10.jpg';
import bgvideo from '../../assets/bgvideo.mp4';

function Home(props) {
  return (
    <section className='home'>
      <div className='background-video'>
        <video autoPlay='autoplay' muted='muted' loop='loop'>
          <source src={bgvideo} type='video/mp4' />
        </video>
        <div className='video-title'>
          <h2>Szabo Music Company</h2>
          <p>Is home to some top musicians from around the world, artists with exceptional talent in a range of styles</p>
          <div>
            <HashLink to='/#contact'>
              <HiOutlineChevronDoubleDown />
            </HashLink>
          </div>
        </div>
      </div>
      <div className='latest-news'>
        <div id='contact'></div>
        <h3>CONTACT US</h3>
        <div class='latest-news-container'>
          <div className='latest-news-contact'>
            <div>
              <MdMessage />
            </div>
            <p>miklos@szabomusiccompany.com</p>
          </div>
          <div className='latest-news-contact'>
            <div>
              <ImMobile />
            </div>
            <p>+36309552088</p>
            <p>+447445449483</p>
          </div>
          <div className='latest-news-contact'>
            <a href='https://www.facebook.com/Szabomusiccompany/' target='_blank' rel='noreferrer'>
              <FaFacebookF />
            </a>
            <p>Find us on Facebook</p>
          </div>
          <div className='latest-news-contact'>
            <a href='https://www.instagram.com/szabomusiccompany/' target='_blank' rel='noreferrer'>
              <FaInstagram />
            </a>
            <p>Find us on Instagram</p>
          </div>
        </div>
      </div>
      <div className='services'>
        <div>
          <RiUserLine />
          <h3>Service</h3>
          <p>We provide a high quality of service from start to finish for all clients.</p>
        </div>
        <div>
          <FaMicrophoneAlt />
          <h3>Performance</h3>
          <p>Our creative team work together with international performers for the best results.</p>
        </div>
        <div>
          <GiCoffeeCup />
          <h3>Travel</h3>
          <p>As a cruise ship musician, you get to travel to the very same places, all around the world, but instead of paying for it, you actually get paid!</p>
        </div>
        <div>
          <BsChatDots />
          <h3>Support</h3>
          <p>Our team is at the heart of our operations and are always happy to help.</p>
        </div>
      </div>
      <div className='clients-container'>
        <h4>Our Musicians Feeds</h4>
      </div>
      <div className='clients'>
        <div>
          <img src={client1} alt='Client' />
          <h3>Julia Biharu, Pianist</h3>
          <p>Szabomusiccompany is the best agency I have ever worked for. Reliable and always keep their word.</p>
          <ImQuotesRight />
        </div>
        <div>
          <img src={client2} alt='Client' />
          <h3>Jess and James, Duo</h3>
          <p>We had a great time on Saga Discovery. Thank you for Szabomusiccompany for arranging this amazing contract for us.</p>
          <ImQuotesRight />
        </div>
        <div>
          <img src={client3} alt='Client' />
          <h3>Gipsy Sun, Duo</h3>
          <p>Szabomusiccompany booked us on Fred Olsen for our first cruise ship gig. Highly recommended.</p>
          <ImQuotesRight />
        </div>
      </div>
      <div className='partner-container'>
        <h3>Our Clients</h3>
        <div className='partner-slideshow'>
          <div className='partner-images'>
            <a href='https://www.fredolsencruises.com/' target='_blank' rel='noreferrer'>
              <img src={partner1} alt='Partner 1' />
            </a>
            <a href='https://www.cunard.com/' target='_blank' rel='noreferrer'>
              <img src={partner2} alt='Partner 2' />
            </a>
            <a href='https://www.carnival.com/' target='_blank' rel='noreferrer'>
              <img src={partner3} alt='Partner 3' />
            </a>
            <a href='https://www.rssc.com/' target='_blank' rel='noreferrer'>
              <img src={partner4} alt='Partner 4' />
            </a>
            <a href='https://www.princess.com/' target='_blank' rel='noreferrer'>
              <img src={partner5} alt='Partner 5' />
            </a>
            <a href='https://www.pocruises.com/' target='_blank' rel='noreferrer'>
              <img src={partner6} alt='Partner 6' />
            </a>
            <a href='https://www.oceaniacruises.com/' target='_blank' rel='noreferrer'>
              <img src={partner7} alt='Partner 7' />
            </a>
            <a href='https://www.ncl.com/fr/en/' target='_blank' rel='noreferrer'>
              <img src={partner8} alt='Partner 8' />
            </a>
            <a href='https://www.silversea.com/' target='_blank' rel='noreferrer'>
              <img src={partner9} alt='Partner 9' />
            </a>
            <a href='/' target='_blank' rel='noreferrer'>
              <img src={partner10} alt='Partner 10' />
            </a>
            <a href='https://www.fredolsencruises.com/' target='_blank' rel='noreferrer'>
              <img src={partner1} alt='Partner 1' />
            </a>
            <a href='https://www.cunard.com/' target='_blank' rel='noreferrer'>
              <img src={partner2} alt='Partner 2' />
            </a>
            <a href='https://www.carnival.com/' target='_blank' rel='noreferrer'>
              <img src={partner3} alt='Partner 3' />
            </a>
            <a href='https://www.rssc.com/' target='_blank' rel='noreferrer'>
              <img src={partner4} alt='Partner 4' />
            </a>
            <a href='https://www.princess.com/' target='_blank' rel='noreferrer'>
              <img src={partner5} alt='Partner 5' />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
