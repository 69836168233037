import React from 'react';
import aboutheader from '../../assets/images/aboutheader.jpg';
import szabomiklos from '../../assets/images/szabomiklos.png';

function About(props) {
  return (
    <section className='section-header'>
      <header style={{ backgroundImage: `url(${aboutheader})` }}>
        <h2>About Us</h2>
      </header>
      <div className='about-header'>
        <h3>Szabo Music Company</h3>
        <p>is a professional music agency that provides musicians to the hotel and cruise industry.</p>
        <p>We offer support and advice to promising musicians looking to develop their career overseas, enjoy wonderful opportunities with a variety of top travel companies, and experience performing on an international platform.</p>
      </div>
      <div className='about-content'>
        <img src={szabomiklos} alt='Szabo Miklos' />
        <div>
          <h4>Miklos Szabo / Managing Director</h4>
          <p>
            Miklos Szabo is a graduate of the Franz Liszt Academy of Music in Budapest, Hungary. He has enjoyed an extensive career internationally as a pianist aboard a number of top cruise lines in the industry, notably Holland America Line, Viking Ocean Cruises, P&O Cruises and Cunard Line. His performing career has taken him around the world and earned him a wealth of experience in the music
            business. As MD of SZABO MUSIC COMPANY, Miklos takes pride in overseeing the development of young musicians, arranging the right position for the right performer and providing professional management to clients.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
