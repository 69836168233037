import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { RiDeleteBin2Fill } from 'react-icons/ri';

import axios from 'axios';

function Admin(props) {
  const [data, setData] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  let history = useHistory();

  const getAllUser = () => {
    const jwt = localStorage.getItem('jwt');
    const url = `${process.env.REACT_APP_BACKEND_HOST}/api/users`;
    const auth = { Authorization: jwt };

    axios({ method: 'post', url, headers: auth })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const addUser = () => {
    const jwt = localStorage.getItem('jwt');
    const url = `${process.env.REACT_APP_BACKEND_HOST}/api/register`;
    const auth = { Authorization: jwt };

    const data = {
      name,
      email,
    };

    axios({ method: 'post', url, data, headers: auth })
      .then((res) => {
        console.log(res.data);
        history.go(0);
      })
      .catch((err) => {
        console.log('Error');
      });
  };

  const deleteUser = (userEmail) => {
    const jwt = localStorage.getItem('jwt');
    const url = `${process.env.REACT_APP_BACKEND_HOST}/api/delete`;
    const auth = { Authorization: jwt };

    const data = {
      email: userEmail,
    };

    axios({ method: 'post', url, data, headers: auth })
      .then((res) => {
        console.log(res.data);
        history.go(0);
      })
      .catch((err) => {
        console.log('Error');
      });
  };

  useEffect(() => {
    getAllUser();
  }, []);

  console.log(data);

  return (
    <div className='admin-container'>
      <h3>Admin</h3>
      <h4>Users</h4>
      <div className='add-user'>
        <input type='text' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
        <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <button onClick={addUser}>Add User</button>
      </div>
      {data && (
        <div>
          <div className='user-container'>
            <div className='user-title'>
              <p>NAME</p>
              <p>EMAIL</p>
            </div>
            {data.map((user, i) => (
              <div className='user-row' key={i}>
                <p>{user.name}</p>
                <p>{user.email}</p>
                <RiDeleteBin2Fill
                  onClick={() => {
                    deleteUser(user.email);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;
