import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';

function Login(props) {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  let history = useHistory();

  const [message, setMessage] = useState(null);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/login`, { code })
      .then((res) => {
        localStorage.setItem('jwt', res.data);
        history.push('/documents');
        history.go(0);
      })
      .catch((err) => {
        setMessage(err.response.data);
      });
  }, [code, history]);

  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: '180px', fontSize: '3rem' }}>{!message ? <h3>Authentication in progress...</h3> : <h3 style={{ fontSize: '3rem' }}>{message}</h3>}</div>
    </div>
  );
}

export default Login;
