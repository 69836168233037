import strings from '../../assets/images/strings.jpg';
import pianist from '../../assets/images/pianist.jpg';
import duos from '../../assets/images/duos.jpg';
import bands from '../../assets/images/bands.jpg';
import stringheader from '../../assets/images/stringheader.jpg';
import pianistheader from '../../assets/images/pianistheader.jpg';
import duoheader from '../../assets/images/duoheader.jpg';
import bandheader from '../../assets/images/bandheader.jpg';
import alaquartet from '../../assets/images/bands/string/alaquartet.jpg';
import alaquartetheader from '../../assets/images/bands/string/alaquartetheader.jpg';
import alaquartet1 from '../../assets/images/bands/string/alaquartet1.jpg';
import alaquartet2 from '../../assets/images/bands/string/alaquartet2.jpg';
import alaquartet3 from '../../assets/images/bands/string/alaquartet3.jpg';
import alaquartetprofile from '../../assets/images/bands/string/alaquartetprofile.jpg';
import elegato from '../../assets/images/bands/1/elegatoprofile.jpg';
import elegatoheader from '../../assets/images/bands/string/elegatoheader.jpg';
import elegatoprofile from '../../assets/images/bands/string/elegatoprofile.jpg';
import elegato1 from '../../assets/images/bands/string/elegato1.jpg';
import elegato2 from '../../assets/images/bands/string/elegato2.jpg';
import impulse2 from '../../assets/images/bands/6/Impulsequintet1.jpg';
import impulse3 from '../../assets/images/bands/6/Impulsequintet2.jpg';
import vica1 from '../../assets/images/bands/vica1.jpg';
import vica2 from '../../assets/images/bands/vica2.jpg';
import vica3 from '../../assets/images/bands/vica3.jpg';
import marcihorvath1 from '../../assets/images/bands/marcihorvath.jpg';
import marcihorvath2 from '../../assets/images/bands/marcihorvath2.jpg';
import iya1 from '../../assets/images/bands/iya2.jpg';
import iya2 from '../../assets/images/bands/iya3.jpg';
import adorjanlakatos1 from '../../assets/images/bands/adorjanlakatos.jpg';
import adorjanlakatos2 from '../../assets/images/bands/adorjanlakatos2.jpg';
import armand from '../../assets/images/bands/armand.jpg';
import mattandsara from '../../assets/images/bands/MattandSara.jpg';
import mattandsara2 from '../../assets/images/bands/MattandSara2.jpg';
import jenniferwilliams from '../../assets/images/bands/JenniferWilliams.jpg';
import george1 from '../../assets/images/bands/George1.jpg';
import george2 from '../../assets/images/bands/George2.jpg';
import clarke from '../../assets/images/bands/clarke.jpg';
import gypsysun from '../../assets/images/bands/gypsysun.jpg';
import elizaduo1 from '../../assets/images/bands/Elizaduo1.jpg';
import elizaduo2 from '../../assets/images/bands/Elizaduo2.jpg';
import nikolett1 from '../../assets/images/bands/nikolett.jpeg';
import nikolett2 from '../../assets/images/bands/nikolett2.jpeg';
import melinda1 from '../../assets/images/bands/melinda1.jpg';
import melinda2 from '../../assets/images/bands/melinda2.jpg';
import rjjrquartet1 from '../../assets/images/bands/rjjrquartet1.jpg';
import rjjrquartet2 from '../../assets/images/bands/rjjrquartet2.jpg';
import AmyBoneJazzQuartet from '../../assets/images/bands/AmyBoneJazzQuartet.jpg';
import AlparPianoEntertainer from '../../assets/images/bands/AlparPianoEntertainer.jpg';
import GaborAuth1 from '../../assets/images/bands/GaborAuth1.jpg';
import GaborAuth2 from '../../assets/images/bands/GaborAuth2.jpg';
import renatoburai from '../../assets/images/bands/renatoburai.jpg';
import NewHorizon from '../../assets/images/bands/NewHorizon.jpg';
import take2 from '../../assets/images/bands/take2.JPG';
import take2new from '../../assets/images/bands/take2new.JPG';
import JoelBowerman from '../../assets/images/bands/JoelBowerman.jpg';
import JoelBowerman2 from '../../assets/images/bands/JoelBowerman2.jpg';
import JuliaBihari1 from '../../assets/images/bands/JuliaBihari1.jpeg';
import JuliaBihari2 from '../../assets/images/bands/JuliaBihari2.jpeg';
import Witty from '../../assets/images/bands/Witty.jpeg';
import missing from '../../assets/images/missing.jpg';

const ArtistsData = [
  {
    // ---------------------------- STRINGS START --------------------------------
    name: 'Strings',
    picture: strings,
    title: 'Strings',
    header: stringheader,
    bands: [
      {
        name: 'A La Quartet Strings',
        picture: alaquartet,
        profile: {
          header: alaquartetheader,
          picture: alaquartetprofile,
          description: `A la quartet is a lovely string quartet from Hungary. The members are exceptional instrumentalists have performed around the world on well known luxurious cruise ships such as TUI Cruise line, AIDA Cruises, Holland America Line and played with world wide famous Hungarian orchestras. Their believe is that "our commitment in music brings joy and delighted moments for us and for our audience as well".`,
          available: `Available for short contract from  2018 September 
          Valid Seaman's book`,
          slider1: alaquartet1,
          slider2: alaquartet2,
          slider3: alaquartet3,
          youtubeone: 'https://www.youtube.com/watch?v=f3rs7_CZzsY',
          youtubetwo: 'https://www.youtube.com/watch?v=hAQL1dfvYFE',
        },
      },
      {
        name: 'Elegato Strings',
        picture: elegato,
        profile: {
          header: elegatoheader,
          picture: elegatoprofile,
          description: `Elegiato is a strings quartet from Moldova. It was established in 2017 by four young, promising musicians. They have played across Europe with different orchestras and had a chance to perform with the famous national orchestra of Moldova. Beside their studies they are regular guest artists on different business events and private concerts.`,
          available: `Available from September 2018`,
          slider1: elegato1,
          slider2: elegato2,
          youtubeone: 'https://www.youtube.com/watch?v=K_D_RLMaeqY',
        },
      },
      {
        name: 'Impulse Quartet',
        picture: impulse3,
        profile: {
          header: impulse2,
          picture: impulse3,
          description: `The members are exceptional instrumentalists who have performed around the world on well known luxurious cruise lines such as NCL, Holland America Line. Before entering the World Wide Web of cocktail parties and themed events onboard, they were all part of a selection of world famous Hungarian orchestras.`,
          slider1: impulse2,
          slider2: impulse3,
          youtubeone: 'https://www.youtube.com/watch?v=41LJkLA2iYo',
        },
      },
    ],
    // ---------------------------- STRINGS END --------------------------------
  },
  {
    // ---------------------------- SOLOIST START --------------------------------
    name: 'Soloist',
    picture: pianist,
    title: 'Soloist',
    header: pianistheader,
    bands: [
      {
        name: 'Julia Bihari',
        picture: JuliaBihari2,
        profile: {
          header: JuliaBihari1,
          picture: JuliaBihari2,
          description: `Julia is a young, passionate and diligent solo pianist from Hungary. Beside her very developed technique, she plays with great sensitivity and awareness. Her rich and expressive style encompasses a wide variety of music including pop favourites, jazz standards, classical pieces, songs from films and musicals. She has played at some special events, concerts, exhibitions, weddings, birthdays. Apart from performing, she also teaches music for all ages in her spare time.`,
          available: `Available from July`,
          slider1: JuliaBihari1,
          slider2: JuliaBihari2,
          youtubeone: 'https://www.youtube.com/watch?v=yAuQztOjeZs',
        },
      },
      {
        name: 'Marcell Horvath',
        picture: marcihorvath2,
        profile: {
          header: marcihorvath2,
          picture: marcihorvath1,
          description: `Marcell is a fresh, young and promising pianist from Hungary. He studied jazz and classical piano from well known Hungarian pianists. Marcell has performed throughout Hungary in various types of places including internationally recognized hotels, high-quality restaurants. Most recently for Hotel Moments, Mala Garden Design Hotel, Hotel Rathaus in Wien, Cosmopolitan Hotel Prague. During his education he has played with orchestras and famous conductors. He has also performed in private events,exhibitions. He has a wide repertoire of different genres from Classical to contemporary Billy Joe or Elton John.`,
          available: `Available from July`,
          slider1: marcihorvath2,
          youtubeone: 'https://www.youtube.com/watch?v=8FTFrZibS-U',
        },
      },
      {
        name: 'Nikolett Maroti',
        picture: nikolett2,
        profile: {
          header: nikolett1,
          picture: nikolett2,
          description: `Nikolett is a young, passionate and diligent solo pianist from Hungary. Beside her very developed technique, she plays with great sensitivity and awareness. Her rich and expressive style encompasses a wide variety of music including pop favourites, jazz standards, classical pieces, songs from films and musicals. She has worked on many hotels around the world and performed at some special events, concerts, exhibitions, weddings, birthdays.`,
          available: `Available from March`,
          slider1: nikolett1,
          slider2: nikolett2,
          youtubeone: 'https://www.youtube.com/watch?v=fyrH_6JnPmQ',
        },
      },
      {
        name: 'Iya Palmas',
        picture: iya2,
        profile: {
          header: iya2,
          picture: iya1,
          description: `Iea is an energetic and experienced piano artist and vocalist from Moldova.
          Iea studied jazz and classical piano at the Academy of Art and Music in Moldova.During her education she has played with several orchestras,jazz trios and quartets. She has strong reading skills and a vast vocal repertoire. 
          Iea is currently performing as a solo and band pianist around the World on numerous beautiful and luxurious cruise ships.`,
          available: `Valid Seaman's book Available from November 2018`,
          slider1: iya2,
          youtubeone: 'https://www.youtube.com/watch?v=3bqbSvb8xSY',
        },
      },
      {
        name: 'George Gazsi',
        picture: george2,
        profile: {
          header: george1,
          picture: george2,
          description: `George is a piano artist from Romania. He comes from a traditional musician family. His playing style combines classical, jazz and Hungarian flavours with great technical skills and that makes his arrangements unique and fantastic. He studied in Gheorghe Dima Music Academy in Cluj-Napoca, Romania. George has played in various types of venues around the world, including internationally recognized hotels, high-quality restaurants and Luxury Cruise ships such as Holland America Line, Cunard.He has a wide repertoire of different genres from Classical to contemporary Billy Joe or Elton John.`,
          slider1: george1,
          slider2: george2,
          youtubeone: 'https://www.youtube.com/watch?v=iiX2cg7A89A',
        },
      },
      {
        name: 'Melinda Potfi',
        picture: melinda1,
        profile: {
          header: melinda1,
          picture: melinda2,
          description: `Melinda is a young, passionate and diligent solo pianist from Hungary. Beside her very developed technique, she plays with great sensitivity and awareness. Her rich and expressive style encompasses a wide variety of music including pop favourites, jazz standards, classical pieces, songs from films and musicals. She has worked on many cruise companies before such as Costa and MSC and some special events, concerts, exhibitions, weddings, birthdays.`,
          available: `Available from October`,
          slider1: melinda1,
          slider2: melinda2,
          youtubeone: 'https://www.youtube.com/watch?v=nq6SdNPmULQ',
        },
      },
      {
        name: 'Joel Bowerman',
        picture: JoelBowerman,
        profile: {
          header: JoelBowerman,
          picture: JoelBowerman2,
          description: `Joel was born March 9 1990 in the city of Hobart, Tasmania, Australia. He was introduced to the piano by his father at the age of 7. His father was an accomplished pianist and gave him lessons for several years. At 13, he began his music career on the streets of Hobart. After a couple of years of this, he was approached by a local hotel owner and offered to play at his establishment. With a population nearly 20 times that of Hobart, he soon found plenty of work including 2 residency positions at hotels. At 26 he decided to excel my music career even further and began employment with the cruise company Holland America Line. Joel completed 7 contracts with this cruise line and gained large popularity with the company and passengers.`,
          slider1: JoelBowerman,
          slider2: JoelBowerman2,
          youtubeone: 'https://www.youtube.com/watch?v=qhxj2Gtifa0',
        },
      },
      {
        name: 'Adorjan Lakatos',
        picture: adorjanlakatos2,
        profile: {
          header: adorjanlakatos1,
          picture: adorjanlakatos2,
          description: `Adorjan is an energetic and experienced piano artist from Hungary.
          Adorjan studied jazz and classical piano from well known Hungarian pianists. Adorjan has played in various types of venues around the world, including internationally recognized hotels, high-quality restaurants and at sea, most recently for The Ritz-Carlton, Kempinski, Four Season, Intercontinental, Aria Hotels and luxury Cruise ships such as Costa Cruise Line, MSC, Fred Olsen. He performs for private functions and events and has also played in smaller, boutique style venues in Budapest.`,
          available: `Valid Seaman's book Available from September 2018`,
          slider1: adorjanlakatos1,
          slider2: adorjanlakatos2,
          youtubeone: 'https://www.youtube.com/watch?v=QBAoL0GoZQc',
        },
      },
      {
        name: 'Melanie Guay',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `MÉLANIE GUAY has been passionate about music her whole life. She took her very first piano lesson at the age of 5, and went on to complete a bachelor degree in popular music. Today, she is living her dream as a singer-songwriter. She received a grant from the Conseil des arts du Québec to write her first album, and released Palais d’hélium in 2011 under the Disques Passeport label to much critical acclaim. Competing with her original material, Mélanie won the grand prize at the Festival en chanson de Petite-Vallée in 2008.`,
          slider1: missing,
          slider2: missing,
          slider3: missing,
          youtubeone: 'https://www.youtube.com/watch?v=FFNIvcKEoC0',
        },
      },
      {
        name: 'Armand Berki',
        picture: armand,
        profile: {
          header: armand,
          picture: armand,
          description: `Armand is a young, passionate and diligent solo pianist from Hungary. Beside his very developed technique, he plays with great sensitivity and awareness. His rich and expressive style encompasses a wide variety of music including pop favourites, jazz standards, classical pieces, songs from films and musicals. He regularly performs on special events, concerts, exhibitions, weddings, birthday parties. Apart from performing, he also teaches music for all ages in his spare time.`,
          available: `Available from July`,
          slider1: armand,
          youtubeone: 'https://www.youtube.com/watch?v=ANAwkRA73eU',
        },
      },
      {
        name: 'Vica Mate',
        picture: vica2,
        profile: {
          header: vica3,
          picture: vica1,
          description: `Vica is an energetic and experienced piano artist from Hungary.
          Vica studied classical piano from well known Hungarian pianists. Vica has played in various types of venues around the world, including internationally recognized hotels, high-quality restaurants and at sea, most recently for National hotel, Kempinski, and luxury Cruise ships such as Viking Cruises. She performs for private functions and events and has also played in smaller, boutique style venues in Budapest.`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: vica3,
          slider2: vica1,
          youtubeone: 'https://www.youtube.com/watch?v=Qf6vw6A1Hf8',
        },
      },
      {
        name: 'Robert Marton',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `Description...`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: missing,
          slider2: missing,
          slider3: missing,
          youtubeone: 'https://www.youtube.com/watch?v=Vq1C6Z3DY28',
        },
      },
      {
        name: 'Alpar Piano Entertainer',
        picture: AlparPianoEntertainer,
        profile: {
          header: AlparPianoEntertainer,
          picture: AlparPianoEntertainer,
          description: `Description...`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: AlparPianoEntertainer,
          youtubeone: 'https://www.youtube.com/watch?v=hWr0WeS41G8',
        },
      },
      {
        name: 'Gabor Auth',
        picture: GaborAuth2,
        profile: {
          header: GaborAuth2,
          picture: GaborAuth2,
          description: `Description...`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: GaborAuth1,
          slider2: GaborAuth2,
          youtubeone: 'https://www.youtube.com/watch?v=D3UcK7f9Y6I',
          youtubetwo: 'https://www.youtube.com/watch?v=TA-yOPWot-Y',
        },
      },
      {
        name: 'Renato Burai',
        picture: renatoburai,
        profile: {
          header: renatoburai,
          picture: renatoburai,
          description: `Description...`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: renatoburai,
          slider2: renatoburai,
          youtubeone: 'https://www.youtube.com/watch?v=By_jT4-mzFo',
          youtubetwo: 'https://www.youtube.com/watch?v=1aOQIWPkSOs',
        },
      },
      {
        name: `Balazs Sz. Wittman 'Witty'`,
        picture: Witty,
        profile: {
          header: Witty,
          picture: Witty,
          description: `Description...`,
          available: `Valid Seaman's book Available from 2020`,
          slider1: Witty,
          youtubeone: 'https://www.youtube.com/watch?v=7bWcewm-Wsw',
          youtubetwo: 'https://www.youtube.com/watch?v=9eoahDzgNFk',
        },
      },
    ],
    // ---------------------------- SOLOIST END --------------------------------
  },
  {
    // ---------------------------- DUOS START --------------------------------
    name: 'Duos',
    picture: duos,
    title: 'Duos',
    header: duoheader,
    bands: [
      {
        name: 'Matt and Sara Duo',
        picture: mattandsara,
        profile: {
          header: mattandsara2,
          picture: mattandsara,
          description: `Matt&Sarah are a professional guitar and vocal duo. The pair have been together for over ten years and have worked in many different areas of entertainment which helps them reach a high level of performance. For the last six years Matt&Sarah have traveled the world as a duo performing with TUI Holidays and P&O Cruises. The couple are mainly acoustic, but also can use high quality backing tracks and an electric guitar covering a very wide selection of musical genres from 50’s to present day. Matt&Sarah pride themselves on variety.`,
          available: `Valid Seaman's book Available from`,
          slider1: mattandsara,
          slider2: mattandsara2,
          youtubeone: 'https://www.youtube.com/watch?v=jmdDNY5cdtE',
        },
      },
      {
        name: 'Clarke and Carter Duo',
        picture: clarke,
        profile: {
          header: clarke,
          picture: clarke,
          description: `Clarke and Carter are an acoustic duo who enjoy delivering jazzy and classy performances to holidaymakers across the World. Clarke and Carter duo has performed onboard Carnival and MSC ships. They are looking forward to new adventures.`,
          available: `Available from March`,
          slider1: clarke,
          youtubeone: 'https://www.youtube.com/watch?v=HBhf2IM82JI',
        },
      },
      {
        name: 'Gypsy Sun Duo',
        picture: gypsysun,
        profile: {
          header: gypsysun,
          picture: gypsysun,
          description: `Hi! We’re Gypsy Sun Duo.
          We've performed at numerous hotel residencies and venues since we formed in 2017. We've played extensively throughout the Middle East and Asia with our unique, 'live lounge' style acoustic covers.
          Our repertoire covers a huge selection of traditional, classic and modern day songs and it's growing all the time. From Fleetwood Mac to Aretha Franklin, Pink Floyd to Ed Sheeran, The Beatles to ACDC, we have something for every venue and their guests.`,
          slider1: gypsysun,
          youtubeone: 'https://www.youtube.com/watch?v=Ln0ef1VJrks',
        },
      },
      {
        name: 'Jess and James Duo',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `Jess and James duo is a stylish young jazz / soul duo offering an extensive repertoire of jazz, swing blues and contemporary. Their repertoire of carefully chosen songs is tailor made to every audience to create the perfect ambience; mixing classic jazz standards with contemporary pop songs, all beautifully rearranged into a soulful style that blends well with their naturally soulful voices. Their sets are beautifully delivered with luxurious harmonies and topped off with a stunning vocal delivery.
 
          The duo is formed of singer Jessica Melody and pianist/ vocalist James Salvador. Based in London and Cardiff, Royal Blue are both music graduates and full-time musicians performing across the UK. James originally studied classical piano at the Royal Welsh College of Music but is a multi-faceted pianist / vocalist and songwriter who is currently writing a soul/pop album with his band SALDIVAR.  Jessica trained in contemporary vocals and has over 8+ experience working in bands, singing in contracts abroad and as a session and soul singer in both Cardiff and London. 
           
          Friendly and personable, both Jess & James have experience with teaching and leading community and dementia choirs. While both busy musicians in their own right, when they come together, a beautiful soul fusion is created with powerful vocals, virtuoso piano and a lively stage presence. They bring all of their collective passion, experience and love for music together to create a truly entertaining and unforgettable show!`,
          slider1: missing,
          slider2: missing,
          slider3: missing,
          youtubeone: 'https://www.youtube.com/watch?v=mPiH7wRWoKo',
        },
      },
      {
        name: 'Eliza duo',
        picture: elizaduo2,
        profile: {
          header: elizaduo1,
          picture: elizaduo2,
          description: `Eliza duo is a professional guitar and vocal duo. The pair have been together for over ten years and have worked in many different areas of entertainment which helps them reach a high level of performance. For the last six years Eliza duo has traveled the world as a duo performing with TUI Holidays and Aida. Currently they work on the Grand Canarian. The couple are mainly acoustic, but also can use high quality backing tracks and an electric guitar covering a very wide selection of musical genres from 50’s to present day.`,
          available: `Valid Seaman's book Available from`,
          slider1: elizaduo1,
          slider2: elizaduo2,
          youtubeone: 'https://www.youtube.com/watch?v=8suHTk5YcQ0',
          youtubetwo: 'https://www.youtube.com/watch?v=m4eIH_srHEA',
        },
      },
      {
        name: 'Sophie and Joseph duo',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `Description...`,
          available: `Valid Seaman's book Available from`,
          slider1: missing,
          slider2: missing,
          youtubeone: 'https://www.youtube.com/watch?v=HWicbaJ9uxU',
        },
      },
      {
        name: 'Take2 duo',
        picture: take2,
        profile: {
          header: take2new,
          picture: take2,
          description: `Description...`,
          available: `Valid Seaman's book Available from`,
          slider1: take2new,
          slider2: take2,
          youtubeone: 'https://www.youtube.com/watch?v=9OgvFfgXV34',
          youtubetwo: 'https://www.youtube.com/watch?v=Eit5UJiJMdE',
        },
      },
      {
        name: 'Wild Embers duo',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `Description...`,
          available: `Valid Seaman's book Available from`,
          slider1: missing,
          slider2: missing,
          youtubeone: 'https://www.youtube.com/watch?v=1Bz2ckDPynA',
        },
      },
    ],
    // ---------------------------- DUOS END --------------------------------
  },
  {
    // ---------------------------- BANDS START --------------------------------
    name: 'Bands',
    picture: bands,
    title: 'Bands',
    header: bandheader,
    bands: [
      {
        name: 'Jazzy Moods',
        picture: missing,
        profile: {
          header: missing,
          picture: missing,
          description: `Jazzy Moods is an incredible mix of talent and experience. Individually they have played at some of the most iconic stages around the world. They have performed on Cruise Ships such as Tui Cruises, Aida , Hotels, TV, and much more. 
          This wonderful quartet plays a wide variety of genres mixed with jazz flavours.`,
          slider1: missing,
          slider2: missing,
          slider3: missing,
          youtubeone: 'https://www.youtube.com/watch?v=cNnmmJ2Jhz8',
        },
      },
      {
        name: 'Amy Bone Jazz Quartet',
        picture: AmyBoneJazzQuartet,
        profile: {
          header: AmyBoneJazzQuartet,
          picture: AmyBoneJazzQuartet,
          description: `The Amy Bone Jazz Quartet are a 4 piece jazz band that collectively have played at prestigious venues all over the world such as Ronnie Scotts, 606 Jazz Club, O2 Shepherd Bush Empire and have worked on cruise lines such as P&O, Thomson, Coloraturas Line and Tallink. Together, they create a rich, warm blend of soulful jazz tones and present a first class performance at every single show.`,
          available: `Valid Seaman's book Available from`,
          slider1: AmyBoneJazzQuartet,
          youtubeone: 'https://www.youtube.com/watch?v=cBdOvasTuxg',
        },
      },
      {
        name: 'Reggie J. Jr Jazz Quartet',
        picture: rjjrquartet1,
        profile: {
          header: rjjrquartet2,
          picture: rjjrquartet1,
          description: `The quartet was founded by Reggie J. Jr who is an exceptional Hungarian pianist. This distinguished group of outstanding musicians have been playing together since their academic years at the Franz Liszt University of Music - and surely many more years of success is to come.`,
          slider1: rjjrquartet2,
          slider2: rjjrquartet1,
          youtubeone: 'https://www.youtube.com/watch?v=NJbpZykJ8GE',
        },
      },
      {
        name: 'Jennifer Williams Jazz Quartet',
        picture: jenniferwilliams,
        profile: {
          header: jenniferwilliams,
          picture: jenniferwilliams,
          description: `This exceptional group of musicians are always ready to adopt famous tunes and rearrange them with a jazzy flavour and the world famous Hungarian flare and temperament. An extensive experience within the cruising industry, and recently on tour - they will be the perfect addition to any sophisticated event`,
          slider1: jenniferwilliams,
          youtubeone: 'https://www.youtube.com/watch?v=YIw2j29YOe4',
        },
      },
      {
        name: 'New Horizon Party Band',
        picture: NewHorizon,
        profile: {
          header: NewHorizon,
          picture: NewHorizon,
          description: `Description...`,
          slider1: NewHorizon,
          youtubeone: 'https://www.youtube.com/watch?app=desktop&v=WG2n-z54H3U',
          youtubetwo: 'https://www.youtube.com/watch?app=desktop&v=VYKh6lyfojQ',
        },
      },
    ],
    // ---------------------------- BANDS END --------------------------------
  },
];

export default ArtistsData;
